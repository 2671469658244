
let selectorContainer='.jobsearch-form-filters-container';
let selectorFilters='.jobsearch-form-filters';
let selectorButton='.jobsearch-form-top__hide';
let filtersState=false;
let speed = 250;


function init () {
	$(selectorButton).on('click', toggleFilter);
}

function toggleFilter(event){
	event.preventDefault();
	if(!filtersState){
		$(selectorButton).html("HIDE FILTERS");
		$(selectorContainer).animate({'height':$(selectorFilters).outerHeight()+"px"},speed,"swing",function(){
			$(selectorContainer).css('height','auto');
		});
	}else{
		$(selectorButton).html("SHOW FILTERS");
		$(selectorContainer).css('height',$(selectorFilters).outerHeight()+"px");
		$(selectorContainer).animate({'height':"0px"},speed);
	}
	filtersState=!filtersState;
}


export default {
	init
}