
import { debounce } from 'underscore';
import animate from './../../global/animate';
import objectFitImages from 'object-fit-images';


class SwapContent {

	constructor($container, selectors = {}) {

		const {
			contentContainer = '.js-swap__content',
			defaultItem = '.js-swap__default',
			focusElems = '.js-swap__focus',
			dataAttr = 'swap'
		} = selectors;

		this.dataAttr = dataAttr;
		this.$container = $container;
		this.$contentContainer = this.$container.find(contentContainer);
		this.$focusElems = this.$container.find(focusElems);
		this.$defaultContent = this.$contentContainer.find(defaultItem);
		this.contentDataArray = [];
		this.contentArray = [];
		this.$currentContent = this.$defaultContent;
		this.outEvent = 'mouseout';
		this.yOffset = this.$container.offset().top;
		this.setupFunction = this.setup.bind(this);

		window.addEventListener('scroll', this.setupFunction);
		$container.on('touchstart', this.setupFunction);
		$container.on('mousemove', this.setupFunction);
	}

	setup(event) {

		var doc = document.documentElement;
		var y = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		if (event.type === 'scroll' && y < this.yOffset - 400) {
			return;
		}

		window.removeEventListener('scroll', this.setupFunction);
		this.$container.off('touchstart', this.setupFunction);
		this.$container.off('mousemove', this.setupFunction);

		this.$focusElems.each((index, elem) => {
			window.requestAnimationFrame(() => {
				let data = $(elem).data(this.dataAttr);
				let content = this.renderContent(data, elem);
				this.contentDataArray.push(data);
				this.contentArray.push(content);
			});
		});
	}

	getContent(elem) {
		let index = this.$focusElems.index(elem);
		return this.contentArray[index];
	}

	getData(elem) {
		let index = this.$focusElems.index(elem);
		return this.contentDataArray[index];
	}

	getFocusElem(elem) {
		let index = this.$focusElems.index(elem);
		return this.$focusElems.eq(index);
	}

	swapContent(event, callback = false) {

		let $content = this.getContent(event.currentTarget);

		if (!$content || !$content.length || event.type === this.outEvent) {
			$content = this.$defaultContent;
		}

		// items to animate
		let $new = $content;
		let $old = this.$currentContent;

		if ($content.get(0) === this.$currentContent.get(0)) {
			this.$currentContent = $new;
			return;
		}
		
		// put new infront with 0 opacity
		$new.appendTo(this.$contentContainer).css({
			opacity:0
		});

		animate.reflow($new);

		$new.addClass('animate');

		$new.css({ opacity: 1 });

		animate.onTransitionEnd($new, function() {
			$new.removeClass('animate');
			if (callback) callback();
		});

		this.$currentContent = $new;
	}

	renderContent(data, focusElem) {
		return null
	}
}


export default  SwapContent;