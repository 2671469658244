
import SwapContent from './SwapContent';
import { debounce } from 'underscore';



class SwapRolearea extends SwapContent {

	constructor($container) {
		super($container);
		this.outEvent = 'mouseout';
		this.timeouts = [];
		this.delay = 400;
		this.$focusElems.on('mouseenter mouseleave', debounce(this.doAnimation, 200).bind(this));
	}

	clearTimeouts() {
		this.timeouts.forEach(function(timeout) {
			clearTimeout(timeout);
		});
	}

	doAnimation(event) {

		const $content = this.getContent(event.currentTarget);
		const data = this.getData(event.currentTarget);
		const $textElem = $content.find('.block--rolearea__text span');
		
		// stop all text animation
		this.clearTimeouts();
		$textElem.html('');

		// do fade animation
		this.swapContent(event);

		if (event.type === this.outEvent) {
			return;
		}

		// do text animation
		if (!data || !data.speedText || !$content.length) {
			return;
		}

		const stringArray = data.speedText.split(" ");

		stringArray.forEach((word, index) => {
			let timeout = setTimeout(() => {
				$textElem.html(word);
			}, (170 * index) + this.delay);
			this.timeouts.push(timeout);
		});
	}

	renderContent(data, focusElem) {

		if (!data || 
			!data.src || 
			!data.backgroundPosition ||
			!data.speedText)
			return null;

		const elem = `
			<div class="block block--rolearea">
				<div class="block--rolearea__image">
					<img
						src="${data.src}" 
						srcset="${data.srcset}" 
						sizes="${data.sizes}"
						style="object-position: ${data.backgroundPosition}; font-family: 
							'object-fit: contain; object-position: ${data.backgroundPosition};'"
					>
				</div>	
				<div class="block--rolearea__text">
					<span class="heading-1">  </span>
				</div>			
			</div>`;

		const $image = $(elem).prependTo(this.$contentContainer);

		return $image;
	}	
}


export default SwapRolearea;