
var $mapContainer = $('.location-map__map');

var loaded = false,
	loading = false,
	callbacks = [],
	mapPin = '',
	isDraggable = $(document).width() > 480 ? true : false,
	mapOptions = {
		zoom: 16,
	    draggable: isDraggable,
	    panControl : false, // left right up down circle
	    scrollwheel: false, // scrollwheel zoom
	    zoomControl : true, // + - controls
	    streetViewControl : true,
	    mapTypeControl: false,
	    overviewMapControl: false
	    // styles: [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}]
	};


mapPin = "https://static.bblabs.co.uk/media/paddypowerbetfair/map-pin/map-pin.png";


function loadGmapsJS(callback) {

	if (callback && loaded) {
		callback();	
		return;
	}

	if (callback) {		
		callbacks.push(callback);
	}

	// load
	if (!loading && !loaded) {
		loading = true;

		var script = document.createElement('script');
		script.type = 'text/javascript';

		script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCvQ2spfkgm7tdmLyhyccapbVIbbxh45i4&callback=googlemapInitialize';
		
		window.googlemapInitialize = function() {
			loading = false;
			loaded = true;
			for (var i = 0; i < callbacks.length; i++) {
				callbacks[i](); // run callbacks
			};
		}

		document.body.appendChild(script);
	}
}


function init() {


	var gmap;

	if (!$mapContainer.length) {
		return false;
	}

	$mapContainer.each(function() {

		let $elem = $(this);
		let lat = $elem.data('lat');
		let lng = $elem.data('lng');
		let name = $elem.data('name');

		if (!lat || !lng) {
			return false;
		}

		loadGmapsJS(function() {

			
			// setup map centre
			mapOptions.center = new google.maps.LatLng(lat, lng);
			// setup map
			gmap = new google.maps.Map($elem[0], mapOptions);
			// add marker
			new google.maps.Marker({
	            position: mapOptions.center,
	            name : name,
	            map: gmap,
	            icon: mapPin
			});
		});

	});

	
}


export default {
	init
}



