// filename: scrollFix/index.js

import jqueryStickyKit from './jquery.sticky-kit.modified';
import breakpoint from './../../global/breakpoint';
import browser from './../../global/browser';
import { debounce } from 'underscore';


let selectors = {
	container: '[data-split]',
	column: '[data-split-column]',
	stick: '[data-split-stick]'
}

let resetClass = 'is-reset';

class ScrollFix {

	constructor($container) {

		this.$container = $container;
		this.$columns = $container.find(selectors.column);

		this.calculate();

		window.addEventListener('load', this.calculate.bind(this));
		window.addEventListener('load', function() {
			$(document.body).trigger("sticky_kit:recalc");
		});
		$(window).on('resize', debounce(this.resizeHandler.bind(this), 50));
		$(window).on('resize', function() {
			setTimeout(function() {
				$(document.body).trigger("sticky_kit:recalc");
			}, 50);
			$(document.body).trigger("sticky_kit:recalc");
		});
	}


	resizeHandler() {
		this.calculate();
	}


	calculate() {

		const self = this;

		this.reset();

		if (!breakpoint.matches('tablet-landscape')) {
			this.$columns.addClass(resetClass);
			this.$columns.trigger("sticky_kit:detach");
			return;
		}

		let $smallest;
		let $largest;
		let viewportHeight = $(window).height();
		let $alwaysFix = null;
		let $alwaysFixOther = null;

		if (this.$columns.length !== 2) return false;

		if (this.$columns.eq(0).innerHeight() > this.$columns.eq(1).innerHeight()) {
			$largest = this.$columns.eq(0);
			$smallest = this.$columns.eq(1);
		} else {
			$smallest = this.$columns.eq(0);
			$largest = this.$columns.eq(1);
		}

		this.$columns.removeClass(resetClass);

		// get always fixed elem
		this.$columns.each(function(i) {

			if ($(this).is(selectors.stick)) {

				$alwaysFix = $(this);

				if (i === 0) $alwaysFixOther = self.$columns.eq(1);
				if (i === 1) $alwaysFixOther = self.$columns.eq(0);

				return false;
			}
		});

		if (browser.isIE(8)) {
			$smallest.height($largest.innerHeight());
			return;
		}

		// special cases for when some items should 
		// always stick in the viewport (e.g. the horse)
		if ($alwaysFix) {
			$alwaysFixOther.css({ 'min-height' : viewportHeight });
			$alwaysFix.height(viewportHeight);
			$alwaysFix.stick_in_parent();
			$(document.body).trigger("sticky_kit:recalc");
			return;
		}

		// if one is larger and the other is smaller, 
		// and the difference is over 150
		// enlarge the smaller to the viewport height and stick it in the viewport
		if (
			$largest.innerHeight() > viewportHeight &&
			$smallest.innerHeight() < viewportHeight &&
			($largest.innerHeight() - viewportHeight > 150)
		) {
			$smallest.height(viewportHeight);
			$smallest.stick_in_parent();
		} 

		// otherwise make smallest same height as large
		else {
			$smallest.height($largest.innerHeight());
			$(this).trigger('sticky_kit:detach');
		}
	}

	reset() {



		// $(document.body).trigger("sticky_kit:recalc");

		// this.$columns.each(function() {
		// 	$(this).css('height', '');
		// 	$(this).trigger('sticky_kit:detach');
		// });	


		this.$columns.css({
			'height' : '',
			'position' : '',
			'min-height' : '',
			'left' : '',
			'top' : '',
			'bottom' : '',
		});

		this.$columns.each(function() {
			// read val to stop async
			this.getBoundingClientRect();
		});	

		$(document.body).trigger("sticky_kit:recalc");

		this.$columns.each(function() {
			// read val to stop async
			this.getBoundingClientRect();
		});	
	}
}



function init() {

	$(selectors.container).each(function() {
		new ScrollFix($(this));
	});
}


export default {
	init
}