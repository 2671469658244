

let selector = '.js-scroll-down';
let speed = 400;
let scrollDistance = $('.homepage-intro').height();



function init () {
	$(selector).on('click', clickHandler);
}


function clickHandler(event) {

	event.preventDefault();
	$('html, body').animate({scrollTop: scrollDistance}, speed);
		
}


export default {
	init
}