import breakpoint from './../global/breakpoint';

let selectorImage = '.parallax-head__image';
let speed = 0.5; 
/* 
	speed: relative to scrolling speed  
		value between 0 - 1
		0: no extra move (image moves with page normally)
		1: images appears still behinde the page
*/
let scroll = 0;

function init () {
	return
	if ($(selectorImage).length > 0) {
		window.addEventListener("scroll", onParallaxScroll);
		onParallaxScroll();
	}
}

function onParallaxScroll(event){
	var scrollN = $(window).scrollTop();
	if (scrollN < 0) scrollN=0;
	if (scrollN != scroll) {
	        scroll = scrollN;
	        if (breakpoint.matches('tablet-landscape')) $(selectorImage).css("marginTop",scroll*0.5+"px");
	    	else $(selectorImage).css("marginTop","0px");
	}
}


export default {
	init
}