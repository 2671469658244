
import SwapContent from './SwapContent';
import browser from './../../global/browser';
import InlineVideo from './../InlineVideo';



class VideoItem {

	constructor($elem, $playButton, data) {

		const options = {
			autoplay : false,
			loop : false,
			muted : false			
		}

		var data = $.extend({}, options, data);

		const progressHTML = `<div class="video-bar__progress"></div>`;

		this.$elem = $elem;
		this.$playButton = $playButton;
		this.$progress = $(progressHTML).prependTo(this.$playButton);

		this.video = new InlineVideo($elem, data).video;

		this.video.addEventListener('play', this.playHandler.bind(this));
		this.video.addEventListener('pause', this.pauseHandler.bind(this));
		this.video.addEventListener('ended', this.reset.bind(this));
		this.interval = null;
	}


	timeupdate(event) {
		let percent = this.video.currentTime/this.video.duration*100;
		this.$progress.css({ width: percent + '%' });
	}


	playHandler() {
		this.$playButton.addClass('is-playing');

		// we use setInterval and requestAnimationFrame because timeupdate is too infrequent
		this.interval = setInterval(() => {
			window.requestAnimationFrame(this.timeupdate.bind(this));
		}, 16);
	}

	pauseHandler() {
		this.$playButton.removeClass('is-playing');
		this.interval = null;
	}

	isPaused() {
		return this.video.paused;
	}

	isPlaying() {
		return ! this.isPaused();
	}

	pause() {
		this.video.pause();
	}

	play() {
		return this.video.play();
	}

	currentTime() {
		return this.video.currentTime;
	}

	reset() {
		this.interval = null;
		this.video.currentTime = 0;

		this.$progress.css('transition', 'none');
		this.$progress.outerHeight();
		this.$progress.css({ width: 0 });
		this.$progress.css('transition', '');
	}
}





class SwapVideo extends SwapContent {

	constructor($container) {
		super($container);
		this.outEvent = false;
		this.$focusElems.on('click', this.clickHandler.bind(this));

		const self = this;

		// text toggle
		let $textContent = $container.find('.js-swap--video__text-content');
		let $videoContent = $container.find('.js-swap--video__video-content');
		let $toggle = $container.find('.js-swap--video__toggle');

		function showText() {
			$toggle.html('Hear the horse talk');
			$videoContent.addClass('hidden');
			$textContent.removeClass('hidden');
			self.pauseAllVideos();
		}

		function showVideos() {
			$toggle.html('Display as text');
			$toggle.removeClass('hidden');
			$videoContent.removeClass('hidden');
			$textContent.addClass('hidden');
		}

		function toggleTextVideo() {
			if ($textContent.hasClass('hidden')) {
				showText();
			} else {
				showVideos();
			}
			return false;
		}

		if (!browser.isIE(8)) {
			showVideos();
			$toggle.on('click', toggleTextVideo);
		}
	}

	renderContent(data, focusElem) {

		if (!data || 
			!data.mp4 || 
			!data.webm)
			return null;

		let $playButton = $(focusElem);
		let $elem = $('<div class="block--video"></div>');

		$elem.prependTo(this.$contentContainer);

		data.videoItem = new VideoItem($elem, $playButton, data);

		return $elem;
	}

	clickHandler(event) {

		let $content = this.getContent(event.currentTarget);
		let $focus = this.getFocusElem(event.currentTarget);
		let data = this.getData(event.currentTarget);

		this.swapContent(event);

		if (data.videoItem.isPaused()) {

			this.contentDataArray.forEach(function(data) {
				if (data.videoItem.isPlaying()) {
					data.videoItem.pause();
				}
				if (data.videoItem.currentTime() > 0) {
					data.videoItem.reset();
				}
			});

			data.videoItem.play();
			
		} else {
			data.videoItem.pause();
		}
		
		return false;
	}

	pauseAllVideos() {
		this.contentDataArray.forEach(function(data) {
			if (data.videoItem.isPlaying()) {
				data.videoItem.pause();
			}
		});
	}
}


export default SwapVideo