// filename: siteHeader.js

import { throttle, debounce } from 'underscore';

let selectors = {
	header : '.header',
	startHeader : '.js-start-header-show'
}

let $header,
	$startHeader,
	elHeight = 0,
	elTop = 0,
	$document = $(document),
	doc = document.documentElement,
	dHeight	= 0,
	$window = $(window),
	wHeight = 0,
	wScrollCurrent = 0,
	wScrollBefore = 0,
	wScrollDiff = 0,
	startAt = false,
	headerTop = 0;


function init() {

	$header = $(selectors.header);

	if (!$header.length) return false;
	$startHeader = $(selectors.startHeader);

	if (!$startHeader.length) {
		$header.css('transition', 'none');
		$header.outerHeight();
		$header.removeClass('hold');
		$header.outerHeight();
		$header.css('transition', '');
		return;
	}

	$header.css('transform','translate3d(0, 0, 0)');
	$header.css('position','sticky');

	recalc();

	let onScroll = () => window.requestAnimationFrame(scrollHandler);

	window.addEventListener('resize', debounce(recalc, 100));
	window.addEventListener('scroll', onScroll, false);

	headerTop = parseInt($header.css('top'));

	recalc();
	onScroll();
}


function recalc() {
	startAt = $startHeader.length ? $startHeader.offset().top : false;
}

function scrollHandler() {

	wScrollCurrent	= (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

	if (startAt && wScrollCurrent > startAt) {
		$header.removeClass('hold');
	} else if (startAt && wScrollCurrent <= startAt) {
		$header.addClass('hold');
	}
}


function hideHeader() {
	$header.addClass('hold');
}


function showHeader() {

	if (!$startHeader.length) {
		$header.removeClass('hold');
		return;
	}

	if (startAt && wScrollCurrent > startAt) {
		$header.removeClass('hold');
	} else if (startAt && wScrollCurrent <= startAt) {
		$header.addClass('hold');
	}
}


export default {
	init,
	hide : hideHeader,
	show : showHeader,
}
