

var settings = {
	elemsSelector : '.js-accordion',
	toggleSelector : '.js-accordion__toggle',
	accordionContent : '.js-accordion__content',
	activeClass : 'is-active'
};

var $elems;

function isOpen($toggle) {
	return ($toggle.hasClass(settings.activeClass));

}

function close($toggle, $content, initial = false) {

	let speed = (initial) ? 0 : 300;
	// set toggle as inactive
	$toggle.removeClass(settings.activeClass);

	// close content
	$content.removeClass(settings.activeClass).slideUp(speed);
}

function open($toggle, $content) {
	// set toggle as active
	$toggle.addClass(settings.activeClass);

	// open content
	$content.addClass(settings.activeClass).slideDown(300);
}


function init() {

	$elems = $(settings.elemsSelector);
	var $allToggles = $(settings.toggleSelector);
	var $allContent = $(settings.accordionContent);

	// hide all by default
	close($allToggles, $allContent, true);

	// for each toggle
	$allToggles.each(function() {

		var $toggle = $(this),
			$content = $toggle.next(settings.accordionContent);

		// do event
		$toggle.on('click', function() {

			if (isOpen($toggle)) {
				// console.log('is Open');
				close($toggle, $content);
			} else {
				// console.log('is nto Open');
				close($allToggles, $allContent);
				open($toggle, $content);
			}
			return false;
		});
	});
}


export default {
	init
}