import breakpoint from './../global/breakpoint/index';

let selector = '.js-scroll-to-horse';
let horseContainer = '.js-horse';
let speed = 400;
let headerHeight = $('.header').height();



function init () {
	$(selector).on('click', clickHandler);
}


function clickHandler(event) {
	// event.preventDefault();

	if (breakpoint.matches('tablet-landscape-max')) {

		var isPlaying = $(this).hasClass('is-playing');

		if (!isPlaying) {
			$('html, body').animate({scrollTop: $(horseContainer).offset().top - headerHeight}, speed);
		}
		
	}
	
}


export default {
	init
}