// filename: menu.js


import animate from './../global/animate.js';
import header from './siteHeader.js';


let classNames = {
	menuOpen: 'menu--is-open',
	menuOverlayShown: 'menu__overlay--is-shown',
	displayBlock: 'show',
	noScroll: 'overflow-hidden',
	expandIconRotate: 'menu__item--expand-rotate'

}


let selectors = {
	menu: '.menu',
	menuOverlay: '.menu__overlay',
	buttonOpen: '.js-menu-open',
	buttonClose: '.js-menu-close',
	menuExpand: '.js-menu-expand',
	menuExpandIcon: '.menu__item--expand > a:after',
	menuSubitems: '.menu__items--expand'
}


let $html = $('html');
let $menu = $(selectors.menu);
let $menuOverlay = $(selectors.menuOverlay);
let $buttonOpen = $(selectors.buttonOpen);
let $buttonClose = $(selectors.buttonClose);
let $menuSubitems = $(selectors.menuSubitems);
let $menuExpand = $(selectors.menuExpand);



let isOpen = false;
let isExpanded = false;


function init() {

	$buttonOpen.on('click', openMenu);
	$buttonClose.on('click', closeMenu);
	$menuOverlay.on('click', closeMenu);
	$menuExpand.on('click', toggleMenuExpand);

	$(document).on('keydown', function(event) {
	 	if (event.which === 27 && isOpen === true) {
	 		closeMenu();
	 	}
	});
}


function openMenu(event) {	

	isOpen = true;

	header.hide();

	// display overlay
	$menuOverlay.addClass(classNames.displayBlock);
	// recalc
	animate.reflow($menuOverlay);
	// animate in opacity
	$menuOverlay.addClass(classNames.menuOverlayShown);
	// open menu and stop HTML from scrolling
	$menu.addClass(classNames.menuOpen);
	$html.addClass(classNames.noScroll);

	return false;
}


function closeMenu(event) {

	isOpen = false;

	header.show();

	$menu.removeClass(classNames.menuOpen);
	$html.removeClass(classNames.noScroll);

	// after animate out
	animate.onTransitionEnd($menuOverlay, function() {
		$menuOverlay.removeClass(classNames.displayBlock);
	});
	// animate out
	$menuOverlay.removeClass(classNames.menuOverlayShown);
	
	return false;
}


function toggleMenuExpand(event) {

	if (isExpanded === false) {

		$menuSubitems.addClass(classNames.displayBlock);
		$menuExpand.addClass(classNames.expandIconRotate);
		isExpanded = true;

	} else {

		$menuSubitems.removeClass(classNames.displayBlock);
		$menuExpand.removeClass(classNames.expandIconRotate);
		isExpanded = false;
	}
	
	return false;
}


export default {
	init
}