

import objectFitVideos from './../../vendor/objectFitVideos';


export default class InlineVideo {

	constructor($elem = false, options = {}) {

		if (!$elem || !$elem.length) return false;

		const videoData = $elem.data('video');

		const defaults = {
			autoplay : true,
			loop : false,
			muted : true,
			webm : '',
			mp4 : '',
		};

		this.$elem = $elem;
		this.settings = $.extend({}, defaults, videoData);
		this.settings = $.extend({}, this.settings, options);

		if (!this.settings.mp4 || !this.settings.webm) throw "No media found";

		this.createVideoElement();

		// do object fit if needed
		objectFitVideos();
	}

	createVideoElement() {

		const autoplay = this.settings.autoplay ? 'autoplay' : '';
		const loop = this.settings.loop ? 'loop' : '';
		const muted = this.settings.muted ? 'muted' : '';
		const webm = this.settings.webm;
		const mp4 = this.settings.mp4;

		const html = `
			<video playsinline ${autoplay} ${loop} ${muted} poster>
				<source type="video/mp4" src="${mp4}">
				<source type="video/webm" src="${webm}">
			</video>
		`;

		const $video = $(html).prependTo(this.$elem);	
		const video = $video.get(0);

		if (enableInlineVideo) enableInlineVideo(video);

		this.video = video;

		if (!loop) {
			this.ie11DisableLoop();
		}

		return video;
	}

	ie11DisableLoop() {
		this.video.addEventListener('ended', () => {

			const hasAttr = this.video.hasAttribute('loop');
			const attr = this.video.getAttribute('loop');

			if (
				!hasAttr ||
				(hasAttr && attr === 'false')
			) {
				this.video.pause();
			}
		});
	}
}