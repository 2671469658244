

let selector = '.js-scroll-to-top';
let speed = 800;


function init () {
	$(selector).on('click', clickHandler);
}


function clickHandler(event) {
	event.preventDefault();
	$('html, body').animate({scrollTop :0}, speed);
}


export default {
	init
}