
import SwapContent from './SwapContent';
import { debounce } from 'underscore';



class SwapImage extends SwapContent {

	constructor($container) {
		super($container);
		this.outEvent = 'mouseout';
		this.$focusElems.on('mouseenter mouseleave', debounce(this.swapContent, 200).bind(this));
	}

	renderContent(data, focusElem) {

		if (!data || 
			!data.src || 
			!data.srcset || 
			!data.sizes || 
			!data.backgroundPosition)
			return null;

		const elem = `<img
				src="${data.src}" 
				srcset="${data.srcset}" 
				sizes="${data.sizes}"
				style="object-position: ${data.backgroundPosition}; font-family: 
					'object-fit: cover; object-position: ${data.backgroundPosition};'"
		>`;

		const $image = $(elem).prependTo(this.$contentContainer);

		return $image;
	}
}


export default SwapImage;