// filename: main.js

"use strict"


// polyfills
import ArrayFindIndex from './polyfills/Array.findIndex';
import rAF from './polyfills/rAF';
import objectFitImages from 'object-fit-images';


// globals
import lity from 'lity';
import browser from './global/browser';


// modules
import scrollFix from './modules/scrollFix';
import menu from './modules/menu';
import siteHeader from './modules/siteHeader';
import videoBackground from './modules/videoBackground';
import scrollToTop from './modules/scrollToTop';
import scrollToHorse from './modules/scrollToHorse';
import scrollToContent from './modules/scrollToContent';
import swapContents from './modules/swapContents';
import accordion from './modules/accordion';
import jobsform from './modules/jobsform';
import map from './modules/map';
import parallaxHead from './modules/parallaxHead';
import videoExpand from './modules/videoExpand';
import carousel from './modules/carousel';



// init polyfills
objectFitImages();



// init modules
browser.addClasses();
scrollFix.init();
menu.init();
siteHeader.init();
videoBackground.init();
scrollToTop.init();
scrollToHorse.init();
scrollToContent.init();
swapContents.init();
accordion.init();
jobsform.init();
map.init();
parallaxHead.init();
videoExpand.init();




// global extras

$('[data-lity]').on('click', () => {
	$('video').each(function() {
		if (!this.isBackgroundVideo) this.pause();
	})
});