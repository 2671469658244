// filename: breakpoints.js

/*
	Usage: 
	breakpoint.on('tablet-portrait', function);
	breakpoint.matches('tablet-portrait');
*/

import matchMedia from './matchMedia';
import matchMediaAddListener from './matchMedia.addListener';


let breakpoints = {
	'mobile' : '(min-width: 30em)',
	'mobile-max' : '(max-width: 29.99em)',
	'mobile-large' : '(min-width: 40.625em)',
	'mobile-large-max' : '(max-width: 40.615em)',
	'tablet-portrait' : '(min-width: 48em)',
	'tablet-portrait-max' : '(max-width: 47.99em)',
	'tablet-landscape' : '(min-width: 64em)',
	'tablet-landscape-max' : '(max-width: 63.99em)',
	'desktop' : '(min-width: 75em)',
	'desktop-max' : '(max-width: 74.99em)',
	'desktop-large' : '(min-width: 90em)',
	'desktop-large-max' : '(max-width: 89.99em)'
}


function create() {
	return new Breakpoint(breakpoints);
}


function Breakpoint(breakpoints) {

	this.breakpoints = {};

	for (let breakpoint in breakpoints) {
		this.breakpoints[breakpoint] = window.matchMedia(breakpoints[breakpoint]);
	}
}


Breakpoint.prototype.on = function(mediaQueryString, func) {

	if (!this.breakpoints[mediaQueryString]) throw 'No media query with name ' + mediaQueryString;
	if (! typeof func == 'function') return false;
	this.breakpoints[mediaQueryString].addListener(func);
}


Breakpoint.prototype.off = function(mediaQueryString, func) {

	if (!this.breakpoints[mediaQueryString]) throw 'No media query with name ' + mediaQueryString;
	if (! typeof func == 'function') return false;
	this.breakpoints[mediaQueryString].removeListener(func);
}


Breakpoint.prototype.matches = function(mediaQueryString) {
	
	if (!this.breakpoints[mediaQueryString]) throw 'No media query with name ' + mediaQueryString;
	return this.breakpoints[mediaQueryString].matches;
}


let instance = new Breakpoint(breakpoints);


export default instance;