
import browser from './../global/browser';
import InlineVideo from './InlineVideo';


const $videoContainer = $('.homepage-intro__video');
const $fallbackImage = $('.homepage-intro__image');
const $toggleButton = $('.homepage-intro__video-toggle');


function init() {

	if (browser.isIE(8)) return; // display fallback image instead

	const video = new InlineVideo($videoContainer, {
		loop : true
	}).video;

	if (!video) return;

	video.isBackgroundVideo = true;

	video.addEventListener('pause', pauseHandler);
	video.addEventListener('play', playHandler);

	function clickHandler() {
		// if is playing
		if (!!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)) {
			video.pause();
		} else {
			video.play();
		}
	}

	$toggleButton.on('click', clickHandler);
}


function pauseHandler() {
	$toggleButton.addClass('paused');
}


function playHandler() {
	$toggleButton.removeClass('paused');
	$fallbackImage.remove();
}


export default {
	init
}