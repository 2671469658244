
import SwapContent from './SwapContent';
import SwapImage from './SwapImage';
import SwapRolearea from './SwapRolearea';
import SwapVideo from './SwapVideo';


function init() {

	$('.js-swap--image').each(function() {
		new SwapImage($(this));
	});

	$('.js-swap--rolearea').each(function() {
		new SwapRolearea($(this));
	});

	$('.js-swap--video').each(function() {
		new SwapVideo($(this));
	});
}


export default {
	init
}