
import isMobile from 'ismobilejs';
import browser from './../global/browser';

let selectorWrapper = ".videoexpand-wrapper";
let selectorImg = ".videoexpand__img";
let videoCount = 0;
let videos = [];

let wrappers = [];
let imgs = [];

function init() {

	wrappers = $(selectorWrapper);
	imgs= wrappers.find(selectorImg);
	
	videoCount = wrappers.length;

	for(let i=0; i<videoCount; i++) {

		let vid = {
			vid : $(wrappers[i]).attr("data-video-embed"),
			ind : i
		};

		videos[i] = vid;

		if ($(wrappers[i]).attr("data-video-embed")) {
			$(imgs[i]).attr("src","//img.youtube.com/vi/"+videos[i].vid+"/maxresdefault.jpg");
		}
	}
}

export default {
	init
}