// filename: animate.js


export function onTransitionEnd(elem, callback = function(){}) {

	if (elem instanceof jQuery) {
		elem = elem.get(0);
	}

	function whichTransitionEvent() {
	    let t;
	    let el = document.createElement('div');
	    let transitions = {
	      'transition':'transitionend',
	      'OTransition':'oTransitionEnd',
	      'MozTransition':'transitionend',
	      'WebkitTransition':'webkitTransitionEnd'
	    }

	    for(t in transitions){
	        if( el.style[t] !== undefined ){
	            return transitions[t];
	        }
	    }
	}

	let transitionEvent = whichTransitionEvent();

	// do callback if supports transition events
	transitionEvent && elem.addEventListener(transitionEvent, function cb(event) {
		callback();
		// only run once
		event.currentTarget.removeEventListener(event.type, cb);
	});

	// do callback straight away if it doesn't
	!transitionEvent && callback();

}

export function reflow(elem) {

	if (elem instanceof jQuery) {
		elem = elem.get(0);
	}

	// cause reflow by reading box metrics
	elem.getBoundingClientRect();
}

export default {
	onTransitionEnd,
	reflow
}